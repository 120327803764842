@import "fonts.css";

@import url('../public/fonts/stylesheet.css');
@import url('megaMenu.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

body div#hubspot-messages-iframe-container {
    bottom: 8rem !important;
    right: 1rem !important;
}

#markdown-container > p,
#markdown-container > ol,
#markdown-container > ol > li,
#markdown-container > ol > li > ol > li,
#markdown-container > ol > li > ol > li > p,
#markdown-container > ol > li > ol > li > ol > li {
    margin-bottom: 1rem;
}

#markdown-container > ol,
#markdown-container > ol > li > ol,
#markdown-container > ol > li > ol > li > ol {
    list-style-type: upper-roman;
}

#markdown-container > ol > li > ol,
#markdown-container > ol > li > ol > li > ol {
    padding-left: 1rem;
}

.accordion-markdown-content > p,
.accordion-markdown-content > ul > li {
    margin-bottom: 0.5rem !important;
}

.accordion-markdown-content > ul > li {
    list-style-type: circle;
    margin-left: 1rem;
}


.variation-product-content > ul {

    list-style: disc;

}

.variation-product-content > ul > li {

    margin-left: 2em;
    margin-bottom: 0.5em;

}

.variation-product-content > h2 {

    font-size: 2em;
    font-weight: bolder;

}

.variation-product-content > h3 {

    font-size: 1.5em;
    font-weight: bold;

}

@media only screen and (max-width: 600px) {
    .variation-product-content > h2 {

        font-size: 1.2em;
        font-weight: 600

    }

    .variation-product-content > h3 {

        font-size: 0.875em;

    }
}

.embla {
    position: relative;
    /*background-color: #f7f7f7;*/
    padding: 20px;
    /*max-width: 670px;*/
    margin-left: auto;
    margin-right: auto;
}

.embla__viewport {
    overflow: hidden;
    width: 100%;
}

.embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
}

.embla__viewport.is-dragging {
    cursor: grabbing;
}

.embla__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    /*margin-left: -10px;*/
}

.embla__slide {
    padding-left: 10px;
    min-width: 100%;
    position: relative;
    flex: 0 0 100px
}

.embla__slide__inner {
    position: relative;
    overflow: hidden;
    /*height: 190px;*/
}

.embla__slide__img {
    /*position: absolute;*/
    /*display: block;*/
    /*top: 50%;*/
    /*left: 50%;*/
    width: auto;
    /*min-height: 100%;*/
    /*min-width: 100%;*/
    /*max-width: none;*/
    transform: translate(-50%, -50%);
}

.embla--thumb {
    padding-top: 0;
    /*margin-top: -12px;*/
}

.embla__container--thumb {
    cursor: default;
    /*margin-left: -8px;*/
}

.embla__slide--thumb {
    padding-left: 8px;
    min-width: 20%;
}

.embla__slide__inner--thumb {
    touch-action: manipulation;
    cursor: pointer;
    border: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    /*height: 100px;*/
    /*width: 100%;*/
    background-color: transparent;
    position: relative;
    display: block;
    overflow: hidden;
}

.embla__slide__thumbnail {
    position: absolute;
    opacity: 0.2;
    top: 0;
    bottom: 0;
    left: -10000%;
    right: -10000%;
    margin: auto;
    min-width: 1000%;
    min-height: 1000%;
    max-width: none;
    /*transform: scale(0.1);*/
    transition: opacity 0.2s;
}

.embla__slide--thumb.is-selected .embla__slide__thumbnail {
    opacity: 1;
}

